<template>
<div id="Carousel">

  <div id="heading">
    <h1>Some of the best movies I have seen</h1>
    <h6>Click on the movie or press left & right arrow keys</h6>
  </div>

  <div id="Carousel__main">
    <div id="red-marker"></div>
    <div id="card-container">
      <div class="aCard"></div>
      <div class="aCard"></div>
      <div class="aCard"></div>
      <div class="aCard"></div>
      <div class="aCard"></div>
      <div class="aCard"></div>
      <div class="aCard"></div>
      <div class="aCard" id="prev"></div>
      <div class="aCard" id="active"></div>
      <div class="aCard" id="next"></div>
      <div class="aCard"></div>
      <div class="aCard"></div>
      <div class="aCard"></div>
      <div class="aCard"></div>
      <div class="aCard"></div>
      <div class="aCard"></div>
      <div class="aCard"></div>
    </div>
  </div>

</div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'Carousel',
  setup () {

    onMounted(() => {

      let container = document.getElementById('card-container');
      container.onmousedown = event => {
        let target = event.target;
        if (target.id === 'prev') moveCarousel('left');
        if (target.id === 'next') moveCarousel('right');
      };

      function moveCarousel (direction) {
        let prev = document.getElementById('prev');
        let active = document.getElementById('active');
        let next = document.getElementById('next');

        if (direction === 'left') {
          if (prev) {
            let prev_prev = prev.previousElementSibling;
            if (prev_prev) prev_prev.id = 'prev';

            prev.id = 'active';

            if (active) active.id = 'next';
            if (next) next.id = '';

            let left = parseInt(container.style.left) || 0;
            container.style.left = left + 210 + 'px';
          }
        }

        if (direction === 'right') {
          if (next) {
            if (prev) prev.id = '';
            if (active) active.id = 'prev';

            next.id = 'active';

            let next_next = next.nextElementSibling;
            if (next_next) next_next.id = 'next';

            let left = parseInt(container.style.left) || 0;
            container.style.left = left - 210 + 'px';
          }
        }
      }

      document.addEventListener('keydown', event => {
        if (event.key === 'ArrowLeft') moveCarousel('left');
        if (event.key === 'ArrowRight') moveCarousel('right');
      });


    });
  },
};
</script>

<style lang="stylus" scoped>
color1 = #2E5266
color2 = #6E8898
color3 = #9FB1BC
color4 = #D3D0CB
$animation-duration = 500ms
$animation-timing = ease-in-out
#Carousel
  position absolute
  overflow hidden
  z-index 100
  top 70px
  right 100px
  bottom 70px
  left 100px
  border-radius 20px
  box-shadow 0 10px 30px 0 rgba(37, 44, 97, 0.15), 0 4px 18px 0 rgba(93, 100, 148, 0.2)
  background-image linear-gradient(#fff, #f5f5fa)
  #heading
    text-align center
    display flex
    flex-direction column
    justify-content space-evenly
    align-items center
    height 100px
    color color1
    background white
    h1
      font 700 25px AvertaStd
  #Carousel__main
    position absolute
    overflow hidden
    z-index 100
    top 100px
    right 0
    bottom 0
    left 0
    background-image linear-gradient(to top, color1, color2)
    box-shadow inset 0 30px 50px -20px rgba(0, 0, 0, 0.32), inset 0 0 10px 0 rgba(0, 0, 0, 0.32)
    #red-marker
      position absolute
      top 0
      left 0
      z-index 1000
      width 100%
      border-top 1px solid black
    #card-container
      position relative
      transition all $animation-duration $animation-timing
      top 50%
      transform translateY(-50%)
      left 0
      height 420px
      display flex
      align-items center
      justify-content center
      .aCard
        background-image linear-gradient(to top, color1, color2)
        transform scale(0.6) translateY(-2rem)
        transition $animation-duration $animation-timing
        pointer-events none
        position relative
        cursor pointer
        z-index 1
        height 320px
        min-width 210px
        opacity 0
        border-radius 5px
        background-repeat no-repeat
        background-position center
        background-size cover
        &:nth-child(1)
          background-image url("../../public/carousel/1917.webp")
        &:nth-child(2)
          background-image url("../../public/carousel/alien.webp")
        &:nth-child(3)
          background-image url("../../public/carousel/amadeus.webp")
        &:nth-child(4)
          background-image url("../../public/carousel/joker.webp")
        &:nth-child(5)
          background-image url("../../public/carousel/iron.webp")
        &:nth-child(6)
          background-image url("../../public/carousel/empire.webp")
          background-size 112%
        &:nth-child(7)
          background-image url("../../public/carousel/irishman.webp")
        &:nth-child(8)
          background-image url("../../public/carousel/blood.webp")
        &:nth-child(9)
          background-image url("../../public/carousel/pk_2.jpg")
        &:nth-child(10)
          background-image url("../../public/carousel/island.webp")
        &:nth-child(11)
          background-image url("../../public/carousel/kong.webp")
        &:nth-child(12)
          background-image url("../../public/carousel/lincon.webp")
        &:nth-child(13)
          background-image url("../../public/carousel/list.webp")
        &:nth-child(14)
          background-image url("../../public/carousel/redemption.webp")
        &:nth-child(15)
          background-image url("../../public/carousel/silence.webp")
        &:nth-child(16)
          background-image url("../../public/carousel/silence_scorsese.webp")
        &:nth-child(17)
          background-image url("../../public/carousel/space.webp")
        &#active
          z-index 3
          transform scale(1.1) translateY(0) translateX(0)
          opacity 1
          pointer-events auto
          transition $animation-duration $animation-timing
        &#prev, &#next
          z-index 2
          transform scale(0.8) translateY(-1rem) translateX(0)
          opacity 0.5
          pointer-events auto
          transition $animation-duration $animation-timing
        &:after
          content ''
          position absolute
          left 0
          bottom -30px
          height 5px
          width 100%
          border-radius 100%
          background-color rgba(0, 0, 0, 1)
          filter blur(10px)
</style>